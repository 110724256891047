.Chatbot-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}


/* Search */
.chatbot-search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.chatbot-search-input {
    width: 50%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.Chatbot-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Button Container */
.Chatbot-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
}

.Chatbot-action-btn {
    padding: 8px 16px;
    border: none;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.Chatbot-add-btn {
    background-color: #28a745;
    color: white;
}

.Chatbot-import-btn {
    background-color: #007bff;
    color: white;
}

.Chatbot-export-btn {
    background-color: #ff9800;
    color: white;
}

.Chatbot-view-btn {
    background-color: #17a2b8;
    color: white;
}

.Chatbot-edit-btn {
    background-color: #ffc107;
    color: white;
}

.Chatbot-delete-btn {
    background-color: #dc3545;
    color: white;
}

/* Table Styling */
.Chatbot-table-container {
    overflow-x: auto;
}

.Chatbot-table {
    width: 100%;
    border-collapse: collapse;

}

.Chatbot-table th, .Chatbot-table td {
    border-bottom: 1px solid #ddd;
    text-align: center;
    padding: 8px 10px;
    font-size: 0.9rem;
    word-wrap: break-word;
}

.Chatbot-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #555;
}

/* Modal Overlay */
.Chatbot-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.Chatbot-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.3s ease-out;
    box-sizing: border-box; /* Ensure padding doesn’t exceed width */
}

/* Modal Title */
.Chatbot-modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Close Button */
.Chatbot-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
}

.Chatbot-close-btn:hover {
    color: white;
}

/* Form Container */
.Chatbot-form-container {
    margin-top: 20px;
}

.form-container select,
.form-container textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.Chatbot-form-select{
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    box-sizing: border-box; /* Ensure inputs stay within modal width */
}
.Chatbot-form-container input, .Chatbot-import-file-input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    box-sizing: border-box; /* Ensure inputs stay within modal width */
}

.Chatbot-form-container input:focus {
    border-color: #007bff;
    outline: none;
}

/* Submit Button */
.Chatbot-submit-btn, .Chatbot-import-action-btn {
    display: block;
    width: 100%;
    background-color: #28a745;
    color: white;
    padding: 12px;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.Chatbot-submit-btn:hover, .Chatbot-import-action-btn:hover {
    background-color: #218838;
}

/* View Chatbot Details */
.Chatbot-view-details-container {
    width: 100%;
    margin-top: 20px;
}

.Chatbot-view-detail-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #ddd;
}

.Chatbot-view-detail-label {
    font-weight: bold;
    color: #555;
    font-size: 0.95rem;
}

.Chatbot-view-detail-data {
    font-size: 0.95rem;
    color: #333;
    text-align: right;
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Import Customer Modal Styles */
.Chatbot-import-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.Chatbot-import-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    height: 300px; /* Make it square-shaped */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    animation: fadeIn 0.3s ease-out;
}

.Chatbot-import-modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.Chatbot-import-file-input {
    margin: 20px 0;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.Chatbot-import-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
}

.Chatbot-import-close-btn:hover {
    color: white; /* Red hover effect */
}

.Chatbot-import-action-btn {
    width: 100%;
    background-color: #007bff;
    color: white;
    padding: 12px;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.Chatbot-import-action-btn:hover {
    background-color: #0056b3;
}


/* View Customer Modal Styles */
.Chatbot-view-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.Chatbot-view-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%; /* Increase width */
    max-width: 900px; /* Increase max-width */
    max-height: 80vh; /* Limit the modal height to 80% of the viewport */
    overflow-y: auto; /* Add vertical scroll if content exceeds modal height */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    box-sizing: border-box;
    animation: fadeIn 0.3s ease-out;
}

.Chatbot-view-modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.Chatbot-view-details-container {
    width: 100%;
}

.Chatbot-view-detail-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #ddd;
}

.Chatbot-view-detail-label {
    font-weight: bold;
    color: #555;
    font-size: 0.9rem;
}

.Chatbot-view-detail-data {
    font-size: 0.9rem;
    color: #333;
    text-align: right;
}

.Chatbot-view-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
}

.Chatbot-view-close-btn:hover {
    color: white; /* Red hover effect */
}


.Chatbots-floor-container {
    margin-top: 20px;
}

.Chatbots-floor-view-modal-subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
    text-align: center;
}

.floor-Chatbots-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    table-layout: fixed; /* Ensure columns have equal width */
}

.floor-Chatbots-table th,
.floor-Chatbots-table td {
    padding: 8px 10px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 0.9rem;
    word-wrap: break-word; /* Ensure long text wraps within cells */
}

.floor-Chatbots-table th {
    background-color: #f7f7f7;
    font-weight: bold;
    color: #333;
}

.floor-Chatbots-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}