
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    gap: 40px;
    padding: 20px;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 20px;
    height: 200px; /* Increased height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Space below each card */
    flex: 1 1 350px; /* Adjusts the basis for growth, allows more flexibility with increased size */
}

/* Medium devices (tablets, less than 768px) */
@media (max-width: 768px) {
    .card {
        width: calc(50% - 20px); /* 2 cards per row, considering the gap */
    }
}

/* Small devices (landscape phones, less than 576px) */
@media (max-width: 576px) {
    .card {
        width: calc(100% - 40px); /* 1 card per row */
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .card {
        width: calc(33% - 40px); /* Adjusted for 3 cards per row */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .card {
        width: calc(33% - 40px); /* Ensures 3 cards per row on larger screens */
    }
}


/* This will only apply to the card being hovered over */
.card:hover {
    transform: scale(3.5); /* Slightly increase the size of the card */
    opacity: 1; /* Ensure the hovered card is fully opaque */
    z-index: 10; /* Make sure the hovered card is above others */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance the shadow for a "lifting" effect */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for transform and shadow */
}

.card-container .card {
    transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s; /* Smooth transitions for transform, opacity, and shadow */
}

.card-container .card:hover {
    transform: scale(1.05); /* Slightly increase the size of the card */
    opacity: 1; /* Make the hovered card fully opaque */
    z-index: 10; /* Ensure the hovered card is above others */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance the shadow for a 'lifting' effect */
}

.card-container .card:not(:hover) {
    opacity: 0.9; /* Reduce opacity of non-hovered cards to make them appear blurred */
}

.dashboard-container {
    display: flex;
    height: 100vh;
}


.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px; /* Ensure the unit is specified */
    height: 100%;
    background-color: #000;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}




.main-content {
    flex-grow: 1;
    padding: 30px;
    margin-left: 270px;
    margin-bottom: 30px;
}

.sidebar button {
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    text-align: left;
    background-color: transparent; /* Make button background transparent */
    color: white; /* Set text color to white */
    border: 2px solid #000; /* Set border color and width */
    border-radius: 15px; /* Set border radius for rounded corners */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition for colors */
}

.sidebar button:hover {
    background-color: #e4e4e4; /* Set a background color on hover */
    color: #000; /* Change text color on hover for better visibility */
    border-color: #000; /* Change border color on hover to match the background */
}

/* Style for active button */
.sidebar button.active {
    background-color: #ddd;
    color: #000; /* Dark text color for active button */
}





h1 {
    color: #000;
}

h2 {
    color: #f9f9f9;
}




@media (max-width: 768px) {
    .sidebar {
        position: static;
        height: auto;
        width: 100%;
    }

    .main-content {
        margin-left: 0;
    }

}




.title {
    text-align: center;
    color: #333;
}






/*----Third Bot*/
.legal-chatbot-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}

.title {
    color: #333;
}

.description-chatbot {
    color: #666;
    margin-bottom: 20px;
}

.chatbot-interface {
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

.welcome-message {
    background-color: #e8e8e8;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.chat-form {
    display: flex;
    gap: 10px;
}

.chat-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #454545;
}

.faq-section {
    text-align: left;
}

@media (max-width: 768px) {
    .chat-form {
        flex-direction: column;
    }

    .submit-btn {
        width: 100%;
    }
}


/*------------------Chatbot 4---------*/
.contract-generation {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}


.wizard, .template-gallery, .ai-benefits {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.template-gallery {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.template-card {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    background-color: #fff;
}

@media (max-width: 768px) {
    .template-gallery {
        grid-template-columns: 1fr;
    }
}


/*-----Setting------*/
.settings-profile {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}


.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    border: 1px solid #ddd;
    background-color: #f4f4f4;
    cursor: pointer;
    margin-right: 5px;
}

.tab-button.active {
    background-color: #ddd;
}

.tab-content {
    text-align: left;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .tabs {
        flex-direction: column;
    }

    .tab-button {
        width: 100%;
        margin-bottom: 5px;
    }
}


/*---------Keyword research--------*/

.keyword-research-tool {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}


.search-section {
    margin-bottom: 30px;
}

.keyword-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 70%;
    margin-right: 10px;
}

.search-button {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #454545;
}

.tutorial-section, .insights-section {
    text-align: left;
    margin-top: 30px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

@media (max-width: 768px) {
    .keyword-input {
        width: 60%;
    }
}


/*

First page CSS

*/
.language-translator {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.title {
    text-align: center;
    color: #333;
}

.description {
    text-align: center;
    margin-bottom: 30px;
    color: #666;
}

.translator-container {
    display: grid;
    gap: 20px;
}

.input-area, .translated-text {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.input-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    font-family: 'Calibri', Arial, sans-serif;
    border-radius: 4px;
    min-height: 150px;
    resize: vertical;
    font-size: 24px;
}

.output-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    font-family: 'Calibri', Arial, sans-serif;
    border-radius: 4px;
    text-align: justify;
    min-height: 150px;
    resize: vertical;
    font-size: 24px;
}

.settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.language-select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.translate-btn, .copy-btn {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.translate-btn:hover, .copy-btn:hover {
    background-color: #454545;
}

@media (max-width: 600px) {
    .settings {
        flex-direction: column;
    }
}


/*

    Speech to text Css

*/

/* style.css */
.tender-contract-comparison {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    color: #333;
    text-align: center;
}

.description {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}

.upload-instructions, .drop-zone {
    background-color: #f9f9f9;
    border: 2px dashed #ddd;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.drop-zone {
    cursor: pointer;
}

.drop-zone p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

/* style.css */
.tender-contract-comparison {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    color: #333;
    text-align: center;
}

.description {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}

.upload-instructions, .drop-zone {
    background-color: #f9f9f9;
    border: 2px dashed #ddd;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.drop-zone {
    cursor: pointer;
}

.drop-zone p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.tButton {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.tButton:hover {
    background-color: #454545;
}

textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: none;
}

@media (max-width: 768px) {
    .tender-contract-comparison {
        padding: 10px;
    }

    .title, .description, .upload-instructions h2, .drop-zone p {
        text-align: left;
    }
}


button:hover {
    background-color: #454545;
}

textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: none;
}

@media (max-width: 768px) {
    .tender-contract-comparison {
        padding: 10px;
    }

    .title, .description, .upload-instructions h2, .drop-zone p {
        text-align: left;
    }
}


/*

    Chatbot update

*/
.chatbot-update-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;

}

.chatbot-update-container h1 {
    color: #333;
    text-align: center;
}

.update-form {
    display: flex;
    flex-direction: column;
}

.file-input, .sect-dropdown, .submit-btn {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.file-list {
    list-style-type: none;
    padding: 0;
}

.file-list li {
    background: #eee;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-btn {
    cursor: pointer;
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
}

.submit-btn {
    background-color: #000;
    color: white;
    cursor: pointer;
    border: none;
}

.sect-dropdown {
    cursor: pointer;
}


/*

    Table

*/
.table-container {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 30px;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    text-align: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f2f2f2;
}

.table tr:hover {
    background-color: #f5f5f5;
}

.delete-btn {
    padding: 5px 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-btn:hover {
    background-color: #ff3333;
}



.pitch-law-chats {
    padding: 10px;
}

.settings-profile {
    max-width: 800px;
    margin: 0 auto;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.chat-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.chat-controls input {
    margin: 0 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.chat-controls button {
    padding: 5px 15px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.chat-controls button:hover {
    background-color: #454545;
}

.chats-table {
    width: 100%;
    border-collapse: collapse;
}

.chats-table th,
.chats-table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

.chats-table th {
    background-color: #f2f2f2;
}

.chats-table tr:nth-child(even) {
    background-color: #f9f9f9;
}


/*

  Update password

*/

/* Base styles for setting cards container and cards */
.setting-cards-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.setting-card {
    background: #fff;
    border-radius: 8px;
    /* Enhance the shadow for a 3D effect */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    box-sizing: border-box;
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
}

/* Scale shadow on hover for interaction feedback */
.setting-card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

/* Style titles in cards */
.setting-card .title {
    margin-bottom: 15px;
    color: #333; /* Prefer a darker color for professional look */
    font-size: 1.25rem; /* Slightly larger title */
}

/* Form and input styles */
.setting-card form {
    display: flex;
    flex-direction: column;
}

.setting-card input[type="text"],
.setting-card input[type="email"],
.setting-card input[type="password"],
.setting-card select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;

}

.setting-card .create-user-form button,
.setting-card .update-form button {
    padding: 10px;
    margin-top: 10px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.setting-card .create-user-form button:hover,
.setting-card .update-form button:hover {
    background-color: #454545;
}

/* Responsive styling */
@media (max-width: 768px) {
    .setting-card {
        flex-basis: 100%;
        max-width: 100%;
    }
}

/* Password field icon styling */
.password-field {
    position: relative;
    display: flex; /* Use flex to align icon with the input */
    align-items: center;
    width: 100%; /* Center align the icon */
}

.password-field input {
    flex-grow: 1; /* Input should grow and fill the space */
}

.password-field i {
    margin-left: -30px; /* Position the icon inside the input field */
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 39%;
    transform: translateY(-50%);
}


/* General styles for the approve button */
.approve-btn {
    background-color: #000; /* Green background */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding around the text */
    text-align: center; /* Center text */
    text-decoration: none; /* No underline */
    display: inline-block; /* Fit the content width */
    font-size: 16px; /* Font size */
    margin: 4px 2px; /* Margin around the button */
    transition: all 0.3s; /* Transition for smooth hover effect */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Hover effect for the button */
.approve-btn:hover {
    background-color: #454545; /* Slightly darker green on hover */
}

/* Styles for the disabled state of the button */
.approve-btn:disabled {
    background-color: #ccc; /* Gray background for disabled state */
    color: #666; /* Darker text for disabled state */
    cursor: not-allowed; /* Cursor to indicate the button is disabled */
    box-shadow: none; /* No shadow for disabled state */
}




/*

    Loader

*/

.signup-action-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px; /* Adjusts the space between the button and the loader */
}

.signup-btn {
    width: auto; /* Adjust according to your layout needs */
    flex-grow: 1; /* Ensures button uses available space */
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.loader {
    color: #ffffff;
    font-size: 5px;
    text-indent: -99999em;
    margin: 0; /* Adjust for alignment */
    position: relative;
    width: 5px; /* Scaled up for visibility */
    height: 5px; /* Scaled up for visibility */
    box-shadow: inset 0 0 0 1px;
    transform: translateZ(0);
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em rgba(0, 51, 102, 0.2), 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.5), -1.8em -1.8em 0 0em #003366;
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em #003366, 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.2), -1.8em -1.8em 0 0em rgba(0, 51, 102, 0.5);
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




/* Search */
.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-input {
    width: 50%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}



.table img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Subtle shadow */
}

.table img:hover {
    transform: scale(1.1); /* Slightly enlarge icons on hover */
    box-shadow: 0 8px 12px rgba(0,0,0,0.2); /* Increased shadow on hover for a "lifting" effect */
}

