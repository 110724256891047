/* Container and Title */
.container {
    max-width: 1400px;
    margin: auto;
    padding: 20px;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Button Container */
.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
}

.form-container {
    margin-top: 20px;
}

.action-btn {
    padding: 8px 16px;
    border: none;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}


/* Search */
.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-input {
    width: 50%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.add-btn {
    background-color: #28a745;
    color: white;
}

.import-btn {
    background-color: #007bff;
    color: white;
}

.export-btn {
    background-color: #ff9800;
    color: white;
}

.view-btn {
    background-color: #17a2b8;
    color: white;
}

.edit-btn {
    background-color: #ffc107;
    color: white;
}

.delete-btn {
    background-color: #dc3545;
    color: white;
}

/* Table Styling */
.table-container {
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #555;
}

/* Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.form-container select,
.form-container textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
}

.close-btn:hover {
    color: white;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.3s ease-out;
    box-sizing: border-box; /* Ensure padding doesn’t exceed width */
}

.modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.form-container input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    box-sizing: border-box; /* Ensure inputs stay within modal width */
}

.form-container input:focus {
    border-color: #007bff;
    outline: none;
}

.submit-btn {
    display: block;
    width: 100%;
    background-color: #28a745;
    color: white;
    padding: 12px;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.submit-btn:hover {
    background-color: #218838;
}


/* Responsive Design */
@media (max-width: 600px) {
    .table th, .table td {
        font-size: 0.9rem;
    }

    .action-btn {
        font-size: 0.8rem;
        padding: 6px 12px;
    }
}


/* Import Customer Modal Styles */
.import-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.import-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    height: 300px; /* Make it square-shaped */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    animation: fadeIn 0.3s ease-out;
}

.import-modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.import-file-input {
    margin: 20px 0;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.import-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
}

.import-close-btn:hover {
    color: white; /* Red hover effect */
}

.import-action-btn {
    width: 100%;
    background-color: #007bff;
    color: white;
    padding: 12px;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.import-action-btn:hover {
    background-color: #0056b3;
}


/* View Customer Modal Styles */
.view-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.view-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    animation: fadeIn 0.3s ease-out;
    box-sizing: border-box;
}

.view-modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.view-modal-subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
    text-align: center;
}

.view-details-container {
    width: 100%;
}

.view-detail-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.view-detail-label {
    font-weight: bold;
    color: #555;
    font-size: 0.9rem;
}

.view-detail-data {
    font-size: 0.9rem;
    color: #333;
    text-align: right;
}

.malls-container {
    margin-top: 20px;
}

.malls-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.malls-table th,
.malls-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 0.9rem;
}

.malls-table th {
    background-color: #f7f7f7;
    font-weight: bold;
    color: #333;
}

.malls-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.view-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
}

.view-close-btn:hover {
    color: white; /* Red hover effect */
}
